<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
  }
  .text-field, .text-value {
    white-space: nowrap;
  }
  .text-value {
    font-weight: 500;
  }
  .row {
    white-space: nowrap;
    word-break: keep-all;
    margin-top: 15px;
  }
  .item {
    display: flex;
    .right {
      margin-left: 62px;
      flex: 1;
      width: 0;
    }
  }
</style>

<style lang="less">
  .resume-details-edu {
    .ivu-timeline {
      display: block;
      width: 100%;
    }
  }
</style>

<template>
  <r-card hborder class="resume-details-edu">
    <span slot="title">教育经历</span>
    <div class="content">
      <Timeline>
        <TimelineItem v-for="item in data" :key="item.id">
          <div class="item">
            <span style="line-height: 1;" class="text-field">{{(item.startDate || '').substr(0, 10).replace(/\-/g, '/')}}—{{(item.endDate || (item.startDate ? '至今' : '')).substr(0, 10).replace(/\-/g, '/')}}</span>
            <div class="right">
              <div class="text-value">{{item.edu || '-'}}·{{item.school || '-'}}·{{item.type || '-'}}</div>
              <div class="row">
                <span class="text-field">专业：</span>
                <span class="text-value">{{item.pro}}</span>
                <span style="margin-left: 40px;" class="text-field">学制：</span>
                <span class="text-value">{{item.xz}}</span>
                <span style="margin-left: 40px;" class="text-field">教育形式：</span>
                <span class="text-value">{{item.jyxs}}</span>
              </div>
              <div class="row" style="display: flex; align-items: flex-start;">
                <span class="text-field">扫描件：</span>
                <r-imgs style="flex: 1; width: 0;" v-if="item.sources" :covers="item.sources.covers" :previews="item.sources.previews" />
              </div>
            </div>
          </div>
        </TimelineItem>
      </Timeline>
    </div>
  </r-card>
</template>

<script>
import RCard from '@/views/resume/components/card'
import RImgs from '@/views/resume/components/imgs'
import { getEduData } from '@/views/resume/libs'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard, RImgs },
  data () {
    return {
      data: []
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getEduData(this.id).then(data => {
        this.data = data.sort((a, b) => {
          return a.startTime > b.startTime
        })
        this.data.forEach(async (v, i) => {
          v.sources = await loadImgs([v], (item) => item.type || '学历证书')
          this.data.splice(i, 1, v)
        })
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
