<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
  }
  .text-field, .text-value {
    white-space: nowrap;
  }
  .text-value {
    font-weight: 500;
  }
  .row {
    white-space: nowrap;
    word-break: keep-all;
    margin-top: 15px;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
  }
</style>

<style lang="less">
  .resume-details-job {
    .ivu-timeline {
      display: block;
      width: 100%;
    }
  }
</style>

<template>
  <r-card hborder class="resume-details-job">
    <span slot="title">奖励信息</span>
    <div class="content">
      <div>
        <div v-for="item in data" :key="item.id" class="item">
          <div class="text-value">{{item.pzwj}}</div>
          <div style="justify-content: space-between;margin-top: 15px;display: flex;">
            <div>
              <span class="text-field">批准机关：</span>
              <span class="text-value">{{item.pzdw}}</span>
            </div>
            <div>
              <span class="text-field">批准日期：</span>
              <span class="text-value">{{item.pzsj}}</span>
            </div>
            <div>
              <span class="text-field">荣誉级别：</span>
              <span class="text-value">{{item.level}}</span>
            </div>
            <div>
              <span class="text-field">奖励类型：</span>
              <span class="text-value">{{item.title}}</span>
            </div>
          </div>
          <div style="margin-top: 15px; display: flex;">
            <span class="text-field">扫描件：</span>
            <r-imgs style="flex: 1;width: 0;" v-if="item.sources" :covers="item.sources.covers" :previews="item.sources.previews" />
          </div>
        </div>
      </div>
    </div>
  </r-card>
</template>

<script>
import RCard from '@/views/resume/components/card'
import RImgs from '@/views/resume/components/imgs'

import { rewPunRequest } from '@/api'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard, RImgs },
  data () {
    return {
      data: []
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      const data = await rewPunRequest.get({ workerId: this.id, type: 'rew' })
      data.sort((a, b) => {
        return a.startDate > b.startDate
      })

      this.data = data

      this.data.forEach(async (item, i) => {
        item.sources = await loadImgs([item], (item) => item.pzwj)
        this.data.splice(i, 1, item)
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
