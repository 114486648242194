<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
  }
  .text-field, .text-value {
    line-height: 3;
  }
  .text-value {
    font-weight: 500;
  }
  .row {
    white-space: wrap;
    word-break: keep-all;
  }
  .work {
    display: flex;
    .left {
      width: 400px;
    }
    .right {
      margin-left: 20px;
      flex: 1;
    }
  }
</style>

<template>
  <div class="work">
    <r-card class="left" hborder>
      <span slot="title">工作信息</span>
      <div class="content" style="flex-direction: column;">
        <div class="row">
          <span class="text-field">兼职职务：</span>
          <span class="text-value">{{positionIdsText}}</span>
        </div>
        <div class="row">
          <span class="text-field">入职方式：</span>
          <span class="text-value">{{data.employmentForm || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">是否专业技术⼈员：</span>
          <span class="text-value">{{data.address || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">专业技术⼈员专业名称：</span>
          <span class="text-value">{{data.shuangjiantiao || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">专业技术⼈员专业类别：</span>
          <span class="text-value">{{data.email || '-'}}</span>
        </div>
        <div class="row">
          <span class="text-field">是否卫⽣专业技术⼈员：</span>
          <span class="text-value">{{data.jobTitle}}</span>
        </div>
      </div>
    </r-card>
    <r-card class="right" hborder>
      <span slot="title">工作经历</span>
      <div class="content">
        <Timeline>
          <TimelineItem v-for="item in workList" :key="item.id">
            <span style="line-height: 1;" class="text-field">{{(item.startTime || '').substr(0, 10).replace(/\-/g, '/')}}—{{(item.endTime || '至今').substr(0, 10).replace(/\-/g, '/')}}</span>
            <span style="margin-left: 50px;line-height: 1;" class="text-value">{{item.orgName}}·{{item.jobTitle}}</span>
          </TimelineItem>
        </Timeline>
      </div>
    </r-card>
  </div>
</template>

<script>
import RCard from '@/views/resume/components/card'
import { getWorkerInfo, getWorkData } from '@/views/resume/libs'

export default {
  components: { RCard },
  data () {
    return {
      data: {},
      workList: []
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    positionList () {
      return this.$store.getters.positionList
    },
    positionMap () {
      let data = {}
      this.positionList.forEach(v => data[v.key] = v.label)
      return data
    },
    positionIdsText () {
      return (this.data.positionIds || '').split(',').map(v => {
        return this.positionMap[v]
      }).filter(v => v).join(',')
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getWorkData(this.id).then(data => {
        this.workList = data.sort((a, b) => {
          return a.startTime > b.startTime
        })
      })
      getWorkerInfo(this.id).then(data => {
        this.data = data
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
